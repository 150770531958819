<template>
  <v-responsive>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;">
      <div style="padding: 30px 0; line-height: 1.8">
        <h1 class="text-center mt-10 mb-10">로그인</h1>

        <form @submit.prevent="formSubmit" method="post">
          <div style="max-width: 320px" class="mx-auto">
            <v-text-field
              label="전화번호 아이디"
              placeholder="전화번호를 입력해주세요"
              outlined
              type="text"
              v-model="username"
            ></v-text-field>

            <v-text-field
              label="비밀번호"
              placeholder="비밀번호"
              outlined
              type="password"
              v-model="password"
            ></v-text-field>

            <v-btn
              type="submit"
              block
              x-large
              color="primary"
              class="font-weight-bold"
            >
              로그인
            </v-btn>
            <v-btn
              type="button"
              block
              outlined
              x-large
              color="primary"
              class="font-weight-bold mt-3"
              to="/join"
            >
              회원가입
            </v-btn>

            <div class="mt-3 text-center">
              <small
                >비밀번호를 잊으셨나요?
                <a @click="goUrl('/resetpassword/')">비밀번호 재설정</a></small
              >
            </div>
            <!--
                      <v-divider class="my-10 "></v-divider>
        <h3 class="text-center text-weight-normal">간편 로그인</h3>
        -->
          </div>
        </form>
      </div>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "Mypage",

  components: {
    //HelloWorld,
  },
  data: () => ({
    username: "",
    password: "",
  }),
  methods: {
    async formSubmit() {
      /*
        const refEmail = await this.$refs.refEmail.validate()
        if (!refEmail.valid) {
          alert(refEmail.errors[0])
          return false
        }
        const refPassword = await this.$refs.refPassword.validate()
        if (!refPassword.valid) {
          alert(refPassword.errors[0])
          return false
        }*/

      this.$store
        .dispatch("login", {
          //login Action
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          if (response.status == 200) {
            console.log(response);


            if(this.$route.query.redirect){
              this.$router.push(this.$route.query.redirect);
            }else{
              this.$router.push({
                name: "Mypage",
              });
            }


          }
        })
        .catch((error) => {
          let response = error.response.data;
          console.log(response);

          let errorTitle = "네트워크오류가 발생했습니다.";
          if (response.data.errorCode == 48) {
            errorTitle = "비밀번호가 맞지않습니다.";
          }
          if (response.data.errorCode == 38) {
            errorTitle = "이미 가입된 아이디가 있습니다.";
          }
          if (response.data.errorCode == 35) {
            errorTitle = "필수 입력 항목을 확인해주세요.";
          }
          if (response.data.errorCode == 36) {
            errorTitle = "이메일형식이 맞지 않습니다.";
          }

          let alertDialogInfo = {
            timeout: 1000,
            //emoji: "😆",
            title: errorTitle,
            //firstLineText: "도움에 감사드립니다",
            //secondLineText: "",
            // thirdLineText: "셋째줄",
          };
          this.$store.dispatch("openAlertDialog", alertDialogInfo);
        });

      return true;
    },
  },
};
</script>
<style></style>
